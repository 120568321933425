import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { get, first, slice, isEqual, orderBy, map, includes, reduce, isNull } from 'lodash'

import SEO from '../../components/atoms/Seo'
import media from '../../utils/media'
import Navbar from '../../components/organisms/Navbar'
import Footer from '../../components/organisms/Footer'
import numeral from '../../config/number'
import LoadingDots from '../../components/atoms/LoadingDots'
import SubmitButton from '../../components/atoms/SubmitButton'
import HomeSectionTitle from '../../components/atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../components/atoms/HomeSectionSubtitle'
import { API_STRIPE } from '../../config'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.lessThan('lg')`
    flex-direction: column;
    align-items: center;
  `}
`
const Title = styled(HomeSectionTitle)`
  text-align: center;
  margin: 76px 0 0 0;
`
const Subtitle = styled(HomeSectionSubtitle)`
  text-align: center;
  margin: 8px 36px 16px 36px;
`
const Bloc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 320px;
  padding: 32px;
  border: ${({ theme, selected }) => `1px solid ${get(theme, selected ? 'stripeBorderGrey' : 'white')}`};
  border-radius: 10px;
  background-color: ${({ theme, selected }) => get(theme, selected ? 'stripeGrey' : 'white')};

  ${media.lessThan('lg')`
    width: 80%;

    &:first-child {
      padding-top: 0;
      margin-bottom: 32px;
    }
  `}
`
const Features = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const Round = styled.div`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 100px;
  background-color: ${({ theme }) => get(theme, 'grey')};
`
const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ size }) => size || '16'}px;
`
const Text = styled.p`
  font-size: ${({ size }) => size || '16'}px;
  margin-top: ${({ top }) => top || '0'}px;
  font-weight: ${({ weight }) => weight || '400'};
  color: ${({ theme, color }) => get(theme, color || 'black')};
`
const Recommand = styled.div`
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  color: ${({ theme, color }) => get(theme, color || 'brownishGrey')};
`
const Height = styled.div`
  height: ${({ height }) => height}px;
`
const Button = styled.div`
  margin: 24px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`
const ButtonContainer = styled(SubmitButton)`
  width: fit-content;
`
const ButtonLink = styled(Link)`
  text-decoration: none;
  font-family: 'Source Sans Pro';
  color: ${({ theme, color }) => get(theme, color || 'white')};
`

const Prices = () => {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const TITLE = 'Trouver Mon Local Pro - Tarifs'
  const DESCRIPTION = 'La grille tarifaire de Trouver Mon Local Pro'

  const idProducts = ['prod_PsyV6nrS96ES2W', 'prod_PsyVEMY2DpGFMT', 'prod_PsyVBqqumxOOlO', 'prod_PsyV1YINCSUj0p']

  const fetchProducts = useCallback(async () => {
    setLoading(true)
    const stripe = require('stripe')(API_STRIPE)
    const products = await stripe.products.list()

    const fullProducts = await reduce(get(products, 'data'), async (accPromise, product) => {
      const acc = await accPromise

      if (isNull(get(product, 'id')) || !includes(idProducts, get(product, 'id'))) {
        return acc
      }

      const prices = await stripe.prices.search({
        query: `product: "${get(product, 'id')}"`
      })

      return [...acc, {
        ...product,
        price: first(get(prices, 'data'))
      }]
    }, Promise.resolve([]))

    setLoading(false)
    setProducts(slice(orderBy(fullProducts, ['price.unit_amount'], ['asc']), 0, 4))
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [])

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
      </SEO>
      <Navbar />
      <Title tag='h1'>Tarifs</Title>
      <Subtitle>
        Nous proposons différentes offres à choisir selon vos besoins, afin de mettre en avant votre annonce<br />
        auprès de notre communauté de professionnels libéraux
      </Subtitle>
      {loading && <LoadingDots />}
      <Container>
        {map(products, (product, idx) => {
          const selected = isEqual(idx, 1)

          return (
            <Bloc selected={selected}>
              <Height height={22}>
                {selected && <Recommand>Recommandé</Recommand>}
              </Height>
              <Height height={92}>
                <Text size={22} weight={700}>{get(product, 'name')}</Text>
                <Text top={12} color='brownishGrey'>{get(product, 'description')}</Text>
              </Height>
              <Height height={42}>
                <Inline size={6}>
                  <Text
                    size={42}
                    weight={700}>
                    {isEqual(idx, 3) ? '' : numeral(get(product, 'price.unit_amount', 100) / 100).format('0.00 $')}
                  </Text>
                  {!isEqual(idx, 3) &&
                    <div>
                      <Text top={0} color='brownishGrey'>par</Text>
                      <Text top={0} color='brownishGrey'>mois</Text>
                    </div>}
                </Inline>
              </Height>
              <Features>
                <h1>Fonctionnalités :</h1>
                {map(get(product, 'features'), feature => {
                  return (
                    <Inline>
                      <Round />
                      <div>{get(feature, 'name')}</div>
                    </Inline>
                  )
                })}
              </Features>
            </Bloc>
          )
        })
        }
      </Container>
      <Button>
        <ButtonContainer>
          <ButtonLink to='/nos-annonces/deposer-une-annonce'>Déposer une annonce</ButtonLink>
        </ButtonContainer>
      </Button>
      <Footer />
    </>
  )
}

export default Prices
